<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Perform arrival control for driver</h4>
        <form action="#" @submit.prevent="handleSubmit">

        <div class="row">
          <div class="col-sm-6" v-for="control in controls">

            <div class="form-group">
              <label>{{control.label}}</label>
              <input type="text" class="form-control" v-model="control['response']" :required="control.required_for_arrival === 'yes'">
              <span v-if="control.required_for_arrival === 'yes'" class="text-danger "> This field is required</span>
              <span v-else class="text-black-50"> This field is optional</span>

            </div>

          </div>
        </div>
        <div class="col-sm-12 mt-4">
          <vue-dropzone
              id="arrivalControlImages"
              ref="arrivalControlImages"
              :use-custom-slot="true"
              :options="dropzoneOptions"
          ><div class="dz-message needsclick">
            <i class="h1 text-muted ri-upload-cloud-2-line"></i>
            <h3>No Departure control images uploaded</h3>
          </div>
          </vue-dropzone>

        </div>
        <div class="col-md-12 mt-4">
          <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
        </div>
        </form>

      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Attach arrival control images</h4>



      </div>
    </div>
  </div>
</template>

<script>
import {rentalsService} from "../../../../apis/rentals.service";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "perform-arrival-control-for-driver",
  data(){
    return {
      controls: [],
      rental: null,

      dropzoneOptions: {
        url: '#',
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 20,
      },

    }
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  watch: {
    '$store.getters.GET_ARRIVAL_RENTAL_CONTROLS': function (c) {
      if(c){
        this.controls = [];
        c.forEach(e => {
          this.controls.push({...e})
        })
      }
    }
  },
  methods: {
    handleSubmit(){


      const formData = new FormData();
      //'carId' => 'required',
      // 'driverUserId' => 'required',
      //     'rentalId' => 'required',
      //     'departureControls' => 'required|array'

      formData.append('carId', this.rental['car_id'])
      formData.append('rentalId', this.rental['id'])
      formData.append('driverUserId', this.rental['driver_id'])
      formData.append('arrivalControls', JSON.stringify(this.controls))

      const files = this.$refs.arrivalControlImages.getAcceptedFiles();
      files.forEach(el => {
        formData.append("images[]", el);
      })


      this.$store.dispatch('showLoader')
      rentalsService.submitDriverArrivalControl(formData).then((response) => {
        this.$store.dispatch('hideLoader')
        if(!response.status){
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        this.$router.push(`/rentals/car-detail/${this.rental['car_id']}`);

        this.$store.dispatch('success', {message: 'Arrival control submitted for driver', showSwal: true})

      })
    }
  },

  created() {
    // this.$store.dispatch('getRentalControls').then((res) => {
    //   const id = this.$route.params['id']
    //   this.$store.dispatch('fetchRentalDetail', id)
    // })
    const id = this.$route.params['id']
    this.$store.dispatch('fetchRentalDetail', id).then((rental) => {
      console.log(rental,'rentals')

      this.rental = rental;

      const aControls = rental['arrivalControls']
      if (aControls){
        this.controls = aControls
      }else{
        this.$store.dispatch('getRentalControls')
      }

      const aControlImages = rental['arrivalControlImages'];
      if(aControlImages){
        aControlImages.forEach(e => {
          let file = { size: e.size, name: e.name, type: e.type };
          let url = e.url;
          // this.$refs.departureControlImages.manuallyAddFile(file, url);
          this.$nextTick(()=>{
            this.$refs.arrivalControlImages.manuallyAddFile(file, url);
          });

        })
      }


    })

  }
}
</script>

<style scoped>

</style>